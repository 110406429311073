import logo from "./logo.svg";
import "./App.css";
import temple from "./assets/temple.png";
import main from "./assets/main.png";
import strip from "./assets/strip.png";
import kabilan from "./assets/kabilan.png";
import kayalmain from "./assets/kayalmain.png";
import kabi1 from "./assets/kabi1.jpeg";
import kabi2 from "./assets/kabi2.jpeg";
import kabi3 from "./assets/kabi3.jpeg";
import kabi4 from "./assets/kabi4.webp";
import kabi5 from "./assets/kabi5.webp";
import kayal1 from "./assets/kayal1.webp";
import kayal2 from "./assets/kayal2.png";
import kayal3 from "./assets/kayal3.webp";
import kayal4 from "./assets/kayal4.png";
import heart from "./assets/heart.gif";
import dj from "./assets/dj.png";
import map from "./assets/map.png";
import map1 from "./assets/map1.png";
import silambam from "./assets/silambam.jpeg";
import time from "./assets/time.png";
import time1 from "./assets/time1.png";
import sidestrip from "./assets/sidestrip.png";
import eventIcon from "./assets/event-icon.png";
import CountdownTimer from "./Counter";
import FadeInSection from "./fadeinsection";
import Button from "react-bootstrap/Button";
import Typewriter from "./typewriter";
import Carousel from "react-bootstrap/Carousel";
import Form from "react-bootstrap/Form";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useRef, useEffect, useState } from "react";
import MyComponent from "./slide";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
function App() {
  const backgroundImageStyle = {
    backgroundImage: "url(" + temple + ")",
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
  };
  gsap.registerPlugin(ScrollTrigger);

  const imageRef = useRef(null);
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const formRef = useRef(null);
  const [SubmitButton, setSubmitButton] = useState("Submit");
  const [refreshdata, setRefreshdata] = useState(true);
  const [UsersMessaes, setUsersMessages] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
    setSubmitButton("submitting...");
    console.log(data);
    save_enquiry(data);
  };
  const headers = {
    type: "application/json",
  };
  useEffect(() => {
    getDatas();
  }, [refreshdata]);
  const getDatas = async () => {
    try {
      const response = await axios.get(
        "https://kabilan-kayalvizhi.gmtechindia.com/events.php",
        { headers }
      );
      const json = response.data;
      console.log(json);
      setUsersMessages(json);
    } catch (error) {
      console.error("There was an error!", error);
      //swal("Error!", "Request failed with status code 404", "error");
    }
  };
  const save_enquiry = async (data) => {
    try {
      const response = await axios.post(
        "https://kabilan-kayalvizhi.gmtechindia.com/events.php",
        data,
        { headers }
      );
      const json = response.data;

      if (json.status === "OK") {
        formRef.current.reset();
        setRefreshdata(!refreshdata);
        swal("Success!", json.message, "success");
      } else {
        swal("Error!", json.message, "error");
      }
    } catch (error) {
      console.error("There was an error!", error);
      swal("Error!", "Request failed with status code 404", "error");
    } finally {
      buttonRef.current.disabled = false;
      setSubmitButton("Submit");
    }
  };

  useEffect(() => {
    const imageElement = imageRef.current;
    const imageElement1 = imageRef1.current;
    const imageElement2 = imageRef2.current;

    const images = [imageElement, imageElement1];

    gsap.to(imageElement, {
      scale: 1.5, // Adjust the max zoom scale as needed
      scrollTrigger: {
        trigger: imageElement,
        start: "top bottom", // Start when the top of the image reaches the bottom of the viewport
        end: "bottom top", // End when the bottom of the image reaches the top of the viewport
        scrub: true, // Smooth scrubbing
      },
    });

    return () => {
      // Cleanup the ScrollTrigger instance on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  const eventDate = "2024-09-08T10:30:59";
  return (
    <div className="main" style={backgroundImageStyle}>
      <section className="headear padder">
        <div className="container text-center">
          <FadeInSection>
            <div className="logo overflow-hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 645 149"
                fill="none"
              >
                <g fill="#000" filter="url(#a)">
                  <path d="m103.887 28.2 14.384 54.312h.496L133.771 28.2h24.924l-27.032 84.072c-.578 1.819-1.901 2.728-3.968 2.728h-18.6c-2.149 0-3.554-.909-4.216-2.728L78.343 28.2h25.544Z" />
                  <path d="M38.44 28.2c3.967 0 6.902 1.819 8.803 5.456l18.6 35.588h.496V28.2h22.816v81.468c0 1.488-.413 2.769-1.24 3.844-.826.992-1.942 1.488-3.348 1.488H73.531c-3.802 0-6.737-1.819-8.804-5.456L42.78 71.848h-.496V115H19.467V33.532c0-1.488.414-2.728 1.24-3.72.827-1.075 1.943-1.612 3.348-1.612H38.44Zm210.136 0v22.692h-31.372c-1.24 0-1.86.703-1.86 2.108v62h-23.436V35.888c0-5.125 2.562-7.688 7.688-7.688h48.98Zm-.248 32.612v20.584h-29.14V60.812h29.14ZM299.721 28.2c2.149 0 3.554.91 4.216 2.728L332.457 115h-51.336l5.828-22.816h13.64l-9.548-32.488h-.496L275.541 115h-24.924l26.536-84.072c.578-1.819 1.901-2.728 3.968-2.728h18.6Zm83.375 0c3.059 0 5.456.703 7.192 2.108 1.819 1.323 3.059 2.81 3.72 4.464.662-1.653 1.86-3.141 3.596-4.464 1.819-1.405 4.258-2.108 7.316-2.108h24.676c10.912 0 16.368 5.497 16.368 16.492V115h-23.436V55.604c0-3.059-1.446-4.588-4.34-4.588h-8.556c-2.893 0-4.34 1.53-4.34 4.588V115h-22.568V55.604c0-3.059-1.446-4.588-4.34-4.588h-8.556c-2.893 0-4.34 1.53-4.34 4.588V115h-23.436V44.692c0-10.995 5.456-16.492 16.368-16.492h24.676Zm102.047 0V115h-23.436V28.2h23.436Zm39.84 0v61.876c0 1.405.62 2.108 1.86 2.108h31.372V115h-48.98c-5.125 0-7.688-2.604-7.688-7.812V28.2h23.436Zm79.914 57.04V115h-23.436V85.24h23.436ZM582.081 28.2v27.9c0 3.059 1.447 4.588 4.34 4.588h13.516c2.894 0 4.34-1.53 4.34-4.588V28.2h23.436v36.704c0 10.995-5.456 16.492-16.368 16.492h-36.332c-10.912 0-16.368-5.497-16.368-16.492V28.2h23.436Z" />
                </g>
                <defs>
                  <filter
                    id="a"
                    width={616.246}
                    height={94.8}
                    x={15.467}
                    y={28.2}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                  >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      result="hardAlpha"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={2} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_24_2"
                    />
                    <feBlend
                      in="SourceGraphic"
                      in2="effect1_dropShadow_24_2"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </FadeInSection>
          <FadeInSection>
            <h2 className="fl heading">WELCOMES YOU ALL</h2>
          </FadeInSection>

          <FadeInSection>
            <h3 className="fb heading">
              {" "}
              <Typewriter text="EAR PIERCING CEREMONY" speed={100}></Typewriter>
            </h3>
          </FadeInSection>
          <FadeInSection>
            <div className="text-center eventDetails position-relative">
              <img src={eventIcon} className="eventDetailsIcon"></img>
              <div className="divid-event"></div>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="eventDetailsbox text-center d-flex justify-content-center mt-3">
              <div className="d-flex align-items-start">
                <img src={time1} style={{ width: 24, height: 24 }} />
                <h3 className="ms-2 fr f24">08-09-2024 10.30am to 12.00pm </h3>
              </div>
              <div className="d-flex ms-md-2 align-items-start bt">
                <img src={map1} style={{ width: 32, height: 32 }} />
                <h3 className="ms-2 fr f24">
                  AMP Complex (near Vishnu Mahal), Walajapet
                  <a
                    href="https://maps.app.goo.gl/VEq6iLFQfL1n84az6"
                    target="new"
                    className="maplink"
                  >
                    Direction
                  </a>
                </h3>
              </div>
            </div>
          </FadeInSection>
          <FadeInSection>
            <CountdownTimer targetDate={eventDate} />
          </FadeInSection>
        </div>
        <MyComponent />
      </section>
      <section className="animationBlock ">
        <div className="text-center position-relative">
          <FadeInSection>
            {" "}
            <img src={main} className="img-fluid mainimg" ref={imageRef}></img>
          </FadeInSection>
          <div className="col-12">
            {" "}
            <FadeInSection>
              <p className="nameBox fb">NARENDIRAN & VIDHYA</p>
            </FadeInSection>
          </div>
          <FadeInSection>
            {" "}
            <img src={strip} className="position-absolute stripCenter"></img>
          </FadeInSection>
          <div className="container">
            <div className="row">
              <div className="col-md-6 pe-0">
                <FadeInSection>
                  <div className="kabilanMainBlock text-md-end pe-0">
                    <img src={kabilan} className="kabilan-main img-fluid"></img>

                    <img
                      src={sidestrip}
                      className="kabilanstripe "
                      ref={imageRef1}
                    ></img>
                  </div>
                  <p className="nameBox fb ">KABILAN</p>
                </FadeInSection>
              </div>
              <div className="col-md-6 p-5">
                <FadeInSection>
                  <div className="row">
                    <div className="col-6 mt-5">
                      <img src={kabi3} className="framePhoto img-fluid "></img>
                    </div>

                    <div className="col-6 ">
                      <img src={kabi4} className="framePhoto img-fluid "></img>
                    </div>

                    <div className="col-6 mt-3">
                      <img src={kabi1} className="framePhoto img-fluid "></img>
                    </div>

                    <div className="col-6 ">
                      <img
                        src={kabi5}
                        className=" mt-30 framePhoto img-fluid "
                      ></img>
                    </div>
                  </div>
                </FadeInSection>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 p-5 order-2 order-md-1">
                <FadeInSection>
                  <div className="row">
                    <div className="col-6 mt-5">
                      <img src={kayal1} className="framePhoto img-fluid "></img>
                    </div>

                    <div className="col-6">
                      <img src={kayal2} className="framePhoto img-fluid "></img>
                    </div>

                    <div className="col-6 mt-3">
                      <img src={kayal3} className="framePhoto img-fluid "></img>
                    </div>

                    <div className="col-6">
                      <img
                        src={kayal4}
                        className=" mt-30 framePhoto img-fluid "
                      ></img>
                    </div>
                  </div>
                </FadeInSection>
              </div>
              <div className="col-md-6 pe-0 order-1 order-md-2">
                <FadeInSection>
                  <div className="kabilanMainBlock text-md-start pe-0">
                    <img src={sidestrip} className="kabilanstripe "></img>
                    <img
                      src={kayalmain}
                      className="kabilan-main img-fluid"
                    ></img>
                  </div>
                  <p className="nameBox fb ">KAYALVIZHI</p>
                </FadeInSection>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="events padder">
        <div className="container">
          <FadeInSection>
            <h2 className="fb heading mb-md-5 mb-3">Events</h2>
          </FadeInSection>
          <div className="row">
            <div className="col-md-6">
              <FadeInSection>
                <img src={dj} className="img-fluid event-img"></img>
                <h3 className="fb mt-3">RAJESHWARI AUDIO DJ & LIGHT MUSIC</h3>
                <div className="d-flex">
                  <img src={time} style={{ width: 24, height: 24 }} />
                  <p className="fr ms-2">10.30am - 11.00am</p>
                </div>
              </FadeInSection>
            </div>
            <div className="col-md-6">
              <FadeInSection>
                <img src={silambam} className="img-fluid event-img"></img>
                <h3 className="fb mt-3">BISHMAR SILAMBAM KUZHU</h3>
                <div className="d-flex">
                  <img src={time} style={{ width: 24, height: 24 }} />
                  <p className="fr ms-2">11.00am - 11.30am</p>
                </div>
              </FadeInSection>
            </div>
          </div>
        </div>
      </section>

      <section className="rsvp-sec pb-3 pb-md-5">
        <div className="container">
          <FadeInSection>
            <h2 className="fb heading mb-md-5 mb-3">RSVP</h2>
          </FadeInSection>
          <div className="row">
            <div className="col-md-6">
              <h3 className="fb mt-3">Please fill our RSVP Form</h3>
              <div className="d-flex">
                <p className="fr">
                  Kindly respond by September 08, 2024. We look forward to
                  celebrating with you!
                </p>
              </div>
              {UsersMessaes && UsersMessaes.length > 0 && (
                <Carousel data-bs-theme="dark">
                  {UsersMessaes.map((dt, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={heart}
                        alt={`Slide ${index + 1}`}
                      />
                      <Carousel.Caption>
                        <p>{dt.message}</p>
                        <h5 dangerouslySetInnerHTML={{ __html: dt.name }} ></h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="col-md-6">
              <FadeInSection>
                <Form
                  ref={formRef}
                  id="enquire_common"
                  method="POST"
                  name="enquire_common"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Your name</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("name", {
                        required: "Please enter your name",
                      })}
                      placeholder="name"
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control
                      type="email"
                      {...register("email", {
                        required: "Please enter your Email",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address",
                        },
                      })}
                      placeholder="email"
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      {...register("message", {
                        required: "Please enter your message",
                      })}
                      rows={3}
                    />
                    {errors.message && (
                      <p className="text-danger">{errors.message.message}</p>
                    )}
                  </Form.Group>

                  <Button className="theme-btn" type="submit" ref={buttonRef}>
                    {SubmitButton}
                  </Button>
                </Form>
              </FadeInSection>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
