import React, { useEffect } from 'react';
import $ from 'jquery';

const MyComponent = () => {
  useEffect(() => {
    $("#banner_nav_top").on('click', function() {
      $('html, body').animate({
        scrollTop: $("#user-div").offset().top
      }, 200);
    });

    // Cleanup the event listener on component unmount
    return () => {
      $("#banner_nav_top").off('click');
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="inner_nav_div" style={{ visibility: "visible" }}>
    <a href="javascript:void(0);" id="banner_nav_top">
      <span>&nbsp;</span>
    </a>
    <div id="user-div" style={{ marginTop: '300px' }}></div>
  </div>
  );
};

export default MyComponent;
