import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const FadeInSection = (props) => {
    const [ref, inView] = useInView({
        triggerOnce: true,  // Animation will trigger once when element comes into view
        threshold: 0.2      // Configure the percentage of element visibility for triggering
    });

    return (
        <div
            ref={ref}
            style={{
                opacity: inView ? 1 : 0,
                transition: 'opacity 700ms ease-in'
            }}
        >
            {props.children}
        </div>
    );
};

export default FadeInSection;