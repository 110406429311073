// components/Typewriter.js

import React, { useEffect, useState } from 'react';

const Typewriter = ({ text, speed = 100, repeatDelay = 2000 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex(index + 1);
      }, speed);

      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setDisplayedText('');
        setIndex(0);
      }, repeatDelay);

      return () => clearTimeout(timeoutId);
    }
  }, [index, text, speed, repeatDelay]);

  return <div style={{minHeight:"72px"}}>{displayedText}</div>;
};

export default Typewriter;
